<template>
    <component :is="addTakeOutDemandx"></component>
</template>
<script>
import { mapGetters } from "vuex";
import addTakeOutDemand_crossevent from '@/template/AddSuperMarket/crossevent.vue'
export default {
    name:'addSuperMarket',
    components: {
        addTakeOutDemand_crossevent,
    },
    computed: {
        ...mapGetters(['event']),
        addTakeOutDemandx: {
            get: function() {
                var componentCode = this.event.tenant_code || 'eventcool';
                return ('addTakeOutDemand_' + componentCode);  
            }    
        }
    },
}
</script>
