<template>
    <div class="addSuperMarket" v-if="!mealRules.newUI" >
        <Header backType="white" :back="true"/>
        <div class="publicHead">
            <p>商超便利</p>
            <Tips :tips="tips" />
        </div>
        <div>
            <div class="normalInputRow">
                <MInput title="预计人数" pla="请输入预计人数" type='tel' must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" pla="请输入预计金额" type='number' must='true' v-model="input.amount"></MInput>
            </div>
            <Btn btnClass="marketSubBtn" v-if="hideshow" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
    </div>
    <div class="addTakeOutDemand2" v-else>
        <Header backType="white" :back="true" :backEvent="true" @backEvent="$router.push('/orderList')">
            <span slot="headText" style="font-size:.17rem;">商超便利</span>
        </Header>
        <div class="addMealDemand-container">
            <AttentionTips :tips="tips" :newUI="true" />
            <div class="normalInputRow">
                <MInput title="预计人数" :labelBlack="true" type='tel' pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" :labelBlack="true" type='number' pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <Btn btnClass="submit_btn" v-if="hideshow" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
import { getCookie } from 'tiny-cookie'
export default {
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
            },
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
            docmHeight: '0', 
            showHeight: 0, 
            hideshow: true, 
            isResize: false,
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail",'mealRules'])
    },
    created(){
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
        }
    },
    mounted(){
        let platForm = navigator.userAgent.toUpperCase();
        let isAndroid = platForm.indexOf('ANDROID') > -1 || platForm.indexOf('LINUX') > -1;
        if(isAndroid){
            window.addEventListener('resize',() => {
                if (!this.isResize) {
                    this.docmHeight = window.document.documentElement.clientHeight || window.innerHeight
                    this.isResize = true
                }
                //实时屏幕高度
                this.showHeight = window.document.documentElement.clientHeight || window.innerHeight
            })
        }
    },
    watch: {
        showHeight: function() {
            if (this.docmHeight !== this.showHeight) {
                this.hideshow = true
            } else {
                this.hideshow = false
            }
        }
    },
    methods: {
        submit() {
            let params = {
                ...this.input,
            };
            params.proposalId = this.event.proposalId;
            if(this.$route.query && this.$route.query.isEdit){
                params.itemId = this.$route.query.itemId;
            }
            let validateResult = this.validate(params, saveTakeOut);
            if (!validateResult) return;
            params.ItemType = 20;
            if(params.amount <= 0){
                this.toast('金额不能为0')
                return
            }
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.back();
                }
            });
        }
    }
};
</script>
<style lang="scss">
.addSuperMarket {
    .marketSubBtn {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 80%;
        border-radius: 0.25rem;
        position: absolute;
        bottom: 0.34rem;
        right: 10%;
        font-size: 0.14rem;
    }
}
    .submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
    }
</style>